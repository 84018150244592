import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Button, Space, Tag, Typography } from "antd";
// import OrderContext from "../../../../context/order/orderContext";
import InvoiceContext from "../../../../context/invoice/invoiceContext";
import Filter from "./Filter";
import { utils, writeFileXLSX } from "xlsx";
// import OrderPieReportChart from "../charts/OrderPieReportChart";

const OrderReport = () => {
  // const [searchText, setSearchText] = useState();
  // const [searchedColumn, setSearchedColumn] = useState();

  // const orderContext = useContext(OrderContext);
  // const { reportOrders, loadingOrders, clearOrders } = orderContext;

  const invoiceContext = useContext(InvoiceContext);
  const { reportInvoices, invoiceLoading, clearInvoices } = invoiceContext;

  // console.log("This is orders", reportInvoices);

  useEffect(() => {
    return () => {
      // clearOrders();
      clearInvoices();
    };
  }, []); // Dependency array includes 'location'

  const columns = [
    {
      title: "Shop",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record?.shop?.name}</Fragment>,
    },
    {
      title: "Date Invoiced",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.invoicedDate
            ? new Intl.DateTimeFormat("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }).format(new Date(record.invoicedDate))
            : ""}
        </Fragment>
      ),
    },

    {
      title: "Serial Number",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{record && record?.order?.serialNumber}</Fragment>,
    },
    {
      title: "Invoice Amount",
      width: "15%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.order?.grandTotal?.shop
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(record.order.grandTotal.shop)
            : ""}
        </Fragment>
      ),
    },

    {
      title: "Standard Material Cost",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.order?.totalMaterialCost?.standard
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(record.order.totalMaterialCost.standard)
            : ""}
        </Fragment>
      ),
    },
    {
      title: "Standard Overhead",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.order?.overhead?.standard
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(record.order.overhead.standard)
            : ""}
        </Fragment>
      ),
    },
    {
      title: "Standard Labor Cost",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.order?.totalLabor?.standard
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(record.order.totalLabor.standard)
            : ""}
        </Fragment>
      ),
    },
    {
      title: "Standard Total Cost",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record?.order?.totalCost?.standard
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(record.order.totalCost.standard)
            : ""}
        </Fragment>
      ),
    },
    {
      title: "Standard Profit",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record &&
            record?.order.profitPercent?.standard.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 })}
        </Fragment>
      ),
    },
    {
      title: "Type",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record?.order.type}
          </Tag>
        </Fragment>
      ),
    },
    // {
    //   title: "Company Profit",
    //   align: "center",
    //   render: (text, record, rowIndex) => (
    //     <Fragment>
    //       {record && record?.profitPercent?.company?.toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 })}
    //     </Fragment>
    //   ),
    // },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record?.status}
          </Tag>
        </Fragment>
      ),
    },
  ];

  // const statusLabels = ["New", "InQueue", "On Hold", "Building", "Finished", "Invoiced", "Canceled"];
  // const statusCounts = statusLabels.map((label) => reportOrders?.filter((order) => order.status === label).length);
  // const statusData = {
  //   labels: statusLabels,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: statusCounts,
  //       backgroundColor: [
  //         "rgba(170, 235, 255)",
  //         "rgba(255, 0, 0)",
  //         "rgba(255, 95, 0)",
  //         "rgba(102, 203, 126)",
  //         "rgba(155, 0, 0)",
  //         "rgba(51, 186, 83)",
  //       ],
  //     },
  //   ],
  // };

  // const typeLabels = ["Inventory", "Customer"];
  // const typeCounts = typeLabels.map((label) => reportOrders?.filter((order) => order.type === label).length);
  // const typeData = {
  //   labels: typeLabels,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: typeCounts,
  //       backgroundColor: [
  //         "rgba(170, 235, 255)",
  //         "rgba(255, 0, 0)",
  //         "rgba(255, 95, 0)",
  //         "rgba(102, 203, 126)",
  //         "rgba(155, 0, 0)",
  //         "rgba(51, 186, 83)",
  //       ],
  //     },
  //   ],
  // };

  // const modelNames = reportOrders?.map((order) => order.model?.name);
  // // Get unique model names
  // const uniqueModelNames = [...new Set(modelNames)];
  // // Count how many orders are associated with each model
  // const modelCounts = uniqueModelNames.map((model) => reportOrders?.filter((order) => order.model?.name === model).length);
  // // Create data for the pie chart
  // const modelData = {
  //   labels: uniqueModelNames,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: modelCounts,
  //       backgroundColor: [
  //         // Add colors for each model. Ensure you have as many colors as models.
  //         "rgba(255, 99, 132)",
  //         "rgba(54, 162, 235)",
  //         "rgba(255, 206, 86)",
  //         "rgba(75, 192, 192)",
  //         "rgba(153, 102, 255)",
  //         "rgba(255, 159, 64)",
  //         // Add more colors if you have more models...
  //       ],
  //     },
  //   ],
  // };

  // const shopNames = reportOrders?.map((order) => order.shop?.name);
  // // Get unique shop names
  // const uniqueShopNames = [...new Set(shopNames)];
  // // Count how many orders are associated with each shop
  // const shopCounts = uniqueShopNames.map((shop) => reportOrders?.filter((order) => order.shop?.name === shop).length);
  // // Create data for the pie chart
  // const shopData = {
  //   labels: uniqueShopNames,
  //   datasets: [
  //     {
  //       label: `Count`,
  //       data: shopCounts,
  //       backgroundColor: [
  //         // Add colors for each model. Ensure you have as many colors as models.
  //         "rgba(255, 99, 132)",
  //         "rgba(54, 162, 235)",
  //         "rgba(255, 206, 86)",
  //         "rgba(75, 192, 192)",
  //         "rgba(153, 102, 255)",
  //         "rgba(255, 159, 64)",
  //         // Add more colors if you have more models...
  //       ],
  //     },
  //   ],
  // };
  const exportToExcel = () => {
    // Prepare data for export
    const exportData = reportInvoices?.map((record) => ({
      Shop: record?.shop?.name || "",
      "Date Invoiced": record?.invoicedDate
        ? new Intl.DateTimeFormat("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }).format(new Date(record.invoicedDate))
        : "",
      "Serial Number": record?.order?.serialNumber || "",
      // "Invoice Amount": record?.order?.grandTotal?.shop
      //   ? new Intl.NumberFormat("en-US", {
      //       style: "currency",
      //       currency: "USD",
      //     }).format(record.order.grandTotal.shop)
      //   : "",
      "Invoice Amount": record?.order?.grandTotal?.shop ? record?.order?.grandTotal?.shop : "",
      "Standard Material Cost": record?.order?.totalMaterialCost?.shop
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(record.order.totalMaterialCost.shop)
        : "",
      "Standard Overhead": record?.order?.overhead?.shop
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(record.order.overhead.shop)
        : "",
      "Standard Labor Cost": record?.order?.totalLabor?.shop
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(record.order.totalLabor.shop)
        : "",
      "Standard Total Cost": record?.order?.totalCost?.shop
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(record.order.totalCost.shop)
        : "",
      "Standard Profit": record?.order?.profitPercent?.standard
        ? record.order.profitPercent.standard.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })
        : "",
      Type: record?.order?.type || "",
      Status: record?.status || "",
    }));

    // Create a worksheet from the JSON data
    const ws = utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Shop Invoices");

    // Write the workbook to a file
    writeFileXLSX(wb, "ShedPro_InvoiceReport.xlsx");
  };

  const calculateTotalAmount = (data) => {
    return data.reduce((total, invoice) => total + (invoice.order?.grandTotal?.shop || 0), 0);
  };

  // I want the count of the reportInvoices.type === "Customer" and "Inventory"
  // I also want the total of the reportInvoices.order.grandTotal.shop for each type

  const customerCount = reportInvoices?.filter((invoice) => invoice.order.type === "Customer").length;
  const inventoryCount = reportInvoices?.filter((invoice) => invoice.order.type === "Inventory").length;

  const customerTotal =
    reportInvoices && calculateTotalAmount(reportInvoices.filter((invoice) => invoice.order.type === "Customer"));
  const inventoryTotal =
    reportInvoices && calculateTotalAmount(reportInvoices.filter((invoice) => invoice.order.type === "Inventory"));

  const total = reportInvoices && calculateTotalAmount(reportInvoices);

  const summary = (pageData) => {
    const total = calculateTotalAmount(pageData);
    return (
      <>
        <Table.Summary.Row>
          {columns.map((column, index) => {
            if (column.title === "Shop") {
              return (
                <Table.Summary.Cell index={index} key={index}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
              );
            }
            if (column.title === "Invoice Amount") {
              return (
                <Table.Summary.Cell index={index} key={index}>
                  <Typography.Text strong>
                    {total.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography.Text>
                </Table.Summary.Cell>
              );
            }
            return <Table.Summary.Cell index={index} key={index} />;
          })}
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <Fragment>
      <div className="row mb-4">
        {/* <div className="col-md-4">
          <h4>{" Invoiced Orders"}</h4>
        </div> */}
        <div className="col-md-6">
          <Filter />
        </div>

        <div className="col-md-2">
          {" "}
          <Button type="primary" onClick={exportToExcel} style={{ marginBottom: "16px" }}>
            Export to Excel
          </Button>
        </div>

        <div className="col-md-4">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Type</th>
                <th>Count</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Customer</td>
                <td>{customerCount && customerCount}</td>
                <td>
                  {customerTotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Inventory</td>
                <td>{inventoryCount && inventoryCount}</td>
                <td>
                  {inventoryTotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{inventoryCount && inventoryCount + customerCount}</td>
                <td>
                  {total?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row"></div>
      <Table
        rowClassName={() => "hover-row"}
        pagination={false}
        // onChange={handleTableChange}
        loading={!invoiceLoading && reportInvoices ? false : true}
        columns={columns}
        dataSource={reportInvoices && reportInvoices}
        rowKey="_id"
        summary={summary}
      />
    </Fragment>
  );
};

export default OrderReport;
