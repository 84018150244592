import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../context/item/itemContext";
import ShopContext from "../../../../../context/shop/shopContext";
import AuthContext from "../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, Button, Form, Input, InputNumber, Card, Typography } from "antd";

const { Title } = Typography;

const defaultValues = {};
const ItemForm = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  const itemContext = useContext(ItemContext);
  const { updateItem, currentItem, clearCurrentItem, clearFilterItem } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    if (currentItem) {
      reset({
        ...currentItem,
        standardValues: {
          ...currentItem.standardValues,
          type: currentItem.type
        },
        // shopValues: {
        //   [currentShop._id]: {
        //     ...currentItem.shopValues?.[currentShop._id],
        //     type: currentItem.type
        //   }
        // }
      });
    }
  }, []);

  const onSubmit = (data) => {
    console.log("This is data", data);
    updateItem(data, currentShop._id);
    navigate(-1);
    clearCurrentItem();
  };

  const setItemSubTotal = (subTotal) => {
    const freight = getValues("standardValues.freight");
    setValue(`standardValues.subTotal`, +subTotal);
    setValue(`standardValues.total`, +subTotal + +freight);
  };

  const setItemFreight = (freight) => {
    const subTotal = getValues("standardValues.subTotal");
    setValue(`standardValues.freight`, +freight);
    setValue(`standardValues.total`, +subTotal + +freight);
  };

  const setExit = () => {
    navigate(-1);
    // clearCurrentDoor();
  };

  const handleTypeChange = (value) => {
    setValue('type', value);
    // setValue('standardValues.type', value);
    // setValue(`shopValues.${currentShop._id}.type`, value);
  };

  return (
    <div className="container-fluid pb-4">
      <div className="row mt-4">
        <div className="col-md-12">
          <Button type="primary" danger onClick={() => setExit()} style={{ float: "right", marginBottom: "20px" }}>
            Back
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Card
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
            }}
          >
            <Title level={4} style={{ marginBottom: "20px", textAlign: "center" }}>
              Update Item
            </Title>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Form.Item label="Name">
                <Controller
                  name="standardValues.name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} />}
                />
              </Form.Item>

              <Form.Item label="Type">
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input 
                      {...field} 
                      onChange={(e) => {
                        field.onChange(e);
                        handleTypeChange(e.target.value);
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item>
                <Controller
                  name="discount"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value}>
                      Inventory Item
                    </Checkbox>
                  )}
                />
              </Form.Item>

              <Form.Item label="Sub-Total">
                <Controller
                  name="standardValues.subTotal"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      style={{ width: "100%" }}
                      step={0.01}
                      onChange={(value) => {
                        field.onChange(value);
                        setItemSubTotal(value);
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item label="Freight">
                <Controller
                  name="standardValues.freight"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      style={{ width: "100%" }}
                      step={0.01}
                      onChange={(value) => {
                        field.onChange(value);
                        setItemFreight(value);
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Form.Item label="Total">
                <Controller
                  name="standardValues.total"
                  control={control}
                  render={({ field }) => <InputNumber {...field} style={{ width: "100%" }} readOnly />}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "16px",
                  }}
                  disabled={user.viewOnly}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default ItemForm;
