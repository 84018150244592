import React, { useState, Fragment, useEffect } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Table, Button } from "antd";
import NumberFormat from "react-number-format";
import { Input } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

export default function Fields({
  control,
  register,
  setValue,
  getValues,
  itemOptions,
  currentShop,
  currentMaterialOrder,
  watch,
  disabled
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const setItemDescription = (description, index) => {
    setValue(`items[${index}].description`, description);
  };

  const setItemQuantity = (quantity, index) => {
    const price = getValues(`items[${index}].newPrice`);
    setValue(`items[${index}].quantity`, quantity);
    const total = (quantity * price).toFixed(2);
    setValue(`items[${index}].total`, total);
    setTotals();
  };

  const setItemChange = (e, index) => {
    console.log("This is e", e);
    const shopValues = e.value.shopValues.find((shopValue) => shopValue.shop === currentShop._id);

    setValue(`items[${index}].item.subTotal`, shopValues.total.toFixed(2));
    setValue(`items[${index}].newPrice`, shopValues.total.toFixed(2));
    setValue(`items[${index}].total`, 0);
    setValue(`items[${index}].quantity`, 0);
    setTotals();
  };

  const setTotals = () => {
    const currentValues = getValues();
    const items = currentValues.items || [];
    const theItemsCost = items.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
    const freight = currentValues.freight || 0;

    // Update grand total
    setValue('grandTotal', theItemsCost.toFixed(2));

    // Only calculate freight if there are items and a total cost
    if (items.length > 0 && theItemsCost > 0) {
      const freightPercent = freight / theItemsCost;
      items.forEach((_, index) => {
        const newItemPrice = getValues(`items[${index}].newPrice`) || 0;
        const itemFreight = newItemPrice * freightPercent;
        setValue(`items[${index}].newFreight`, itemFreight);
      });
    }
  };

  const onRemoveItem = () => {
    setTotals();
  };

  const setItemPrice = (value, index) => {
    const quantity = getValues(`items[${index}].quantity`);
    setValue(`items[${index}].newPrice`, value);
    const total = (quantity * value).toFixed(2);
    setValue(`items[${index}].total`, total);
    setTotals();
  };

  // function FreightWatch({ control, itemIndex }) {
  //   const freight = useWatch({
  //     control,
  //     name: "freight",
  //   });
  //   const newItemPrice = getValues(`items[${itemIndex}].newPrice`);
  //   const grandTotal = getValues("grandTotal");
  //   const freightPercent = freight / grandTotal;
  //   const itemFreight = newItemPrice && freightPercent * newItemPrice;
  //   setValue(`items[${itemIndex}].newFreight`, itemFreight);
  //   return <div></div>;
  // }

  // function GrandTotalWatch({ control, itemIndex }) {
  //   const grandTotal = useWatch({
  //     control,
  //     name: "grandTotal",
  //   });
  //   const newItemPrice = getValues(`items[${itemIndex}].newPrice`);
  //   const freight = getValues(`freight`);
  //   const freightPercent = freight / grandTotal;
  //   const itemFreight = newItemPrice && freightPercent * newItemPrice;
  //   setValue(`items[${itemIndex}].newFreight`, itemFreight);
  //   return <div></div>;
  // }

  const freight = watch("freight");

  useEffect(() => {
    fields.forEach((field, index) => {
      const newItemPrice = getValues(`items[${index}].newPrice`);
      const grandTotal = getValues("grandTotal");
      const freightPercent = freight / grandTotal;
      const itemFreight = newItemPrice && freightPercent * newItemPrice;
      setValue(`items[${index}].newFreight`, itemFreight);
    });
  }, [freight, fields, getValues, setValue]);

  const setItemTotal = (newTotal, index) => {
    setValue(`items[${index}].total`, newTotal);
    const quantity = getValues(`items[${index}].quantity`);
    setValue(`items[${index}].newPrice`, newTotal / quantity);
    setTotals();
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: "30%",
      render: (text, record, index) => {
        return (
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={itemOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(itemOptions && itemOptions.find((option) => option.value._id === field.value?._id)) || null}
                onChange={(e) => {
                  field.onChange(e.value);
                  setValue(`items[${index}].item`, e.value);
                  setItemChange(e, index);
                }}
                isDisabled={disabled}
              />
            )}
            name={`items[${index}].item`}
            control={control}
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control inputbg"
              name={name}
              {...field}
              onChange={(e) => {
                const description = e.target.value;
                setItemDescription(description, index);
              }}
            />
          )}
          control={control}
          name={`items[${index}].description`}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record, index) => (
        <Controller
          render={({ field, name }) => (
            <input
              className="form-control inputbg"
              type="number"
              step="any"
              name={name}
              {...field}
              onChange={(e) => {
                const quantity = e.target.value;
                setItemQuantity(quantity, index);
              }}
            />
          )}
          control={control}
          name={`items[${index}].quantity`}
        />
      ),
    },

    // {
    //   title: "Old Price Total",
    //   dataIndex: "oldPrice",
    //   key: "oldPrice",
    //   render: (text, record, index) => (
    //     <Controller
    //       render={({ field, onChange, name }) => (
    //         <NumberFormat
    //           readOnly
    //           customInput={Input}
    //           className="form-group form-control"
    //           style={{ fontSize: 16, padding: 6 }}
    //           {...field}
    //           name={name}
    //           thousandSeparator={true}
    //           prefix={"$ "}
    //           displayType="input"
    //           decimalScale={2}
    //           fixedDecimalScale="true"
    //         />
    //       )}
    //       name={`items[${index}].item.total`}
    //       control={control}
    //     />
    //   ),
    // },
    {
      title: "New Price",
      dataIndex: "newPrice",
      key: "newPrice",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control"
              type="number"
              step="any"
              {...field}
              name={name}
              onChange={(e) => {
                const value = e.target.value;
                setItemPrice(value, index);
              }}
              {...restProps}
            />
          )}
          control={control}
          name={`items[${index}].newPrice`}
        />
      ),
    },
    {
      title: "New Freight",
      dataIndex: "newFreight",
      key: "newFreight",
      render: (text, record, index) => (
        <Controller
          render={({ field, onChange, name }) => (
            <NumberFormat
              readOnly
              customInput={Input}
              className="form-group form-control"
              style={{ fontSize: 16, padding: 6 }}
              {...field}
              name={name}
              thousandSeparator={true}
              prefix={"$ "}
              displayType="input"
              decimalScale={2}
              fixedDecimalScale="true"
            />
          )}
          name={`items[${index}].newFreight`}
          control={control}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => (
        <Controller
          render={({ value, field, onChange, name, ...restProps }) => (
            <input
              className="form-control"
              type="number"
              step="any"
              {...field}
              name={name}
              onChange={(e) => {
                const value = e.target.value;
                setItemTotal(value, index);
              }}
              {...restProps}
            />
          )}
          control={control}
          name={`items[${index}].total`}
        />
      ),
    },
    {
      title: "Action", 
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <Button 
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => handleDeleteLine(e, index)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDeleteLine = async (e, index) => {
    e.preventDefault();
    console.log('Before delete - fields:', fields);
    console.log('Before delete - form values:', getValues());
    
    // Remove the item
    remove(index);

    // Wait for the next render cycle
    await new Promise(resolve => setTimeout(resolve, 0));

    // Get updated values and recalculate totals
    const updatedValues = getValues();
    console.log('After delete - form values:', updatedValues);

    // Update the grand total based on remaining items
    const remainingItems = updatedValues.items || [];
    const newTotal = remainingItems.reduce((acc, curr) => acc + parseFloat(curr.total || 0), 0);
    setValue('grandTotal', newTotal.toFixed(2));

    // Update freight calculations for remaining items
    if (remainingItems.length > 0) {
      const freight = updatedValues.freight || 0;
      const freightPerItem = freight / newTotal;
      
      remainingItems.forEach((_, itemIndex) => {
        const itemPrice = getValues(`items[${itemIndex}].newPrice`) || 0;
        const itemFreight = itemPrice * freightPerItem;
        setValue(`items[${itemIndex}].newFreight`, itemFreight);
      });
    }
  };

  const formValues = watch(); // Get the current form values

  const data = fields.map((field, index) => {
    return {
      key: index,
      id: field._id,
      metalPackage: field.metalPackage,
      // ... other fields
    };
  });
  // Augment each metalOrder record with its index
  const dataSourceWithIndex = data.map((record, index) => ({ ...record, nestIndex: index }));

  return (
    <div className="mt-4 ">
      <Table dataSource={dataSourceWithIndex} columns={columns} pagination={false} />

      <Button
        type="dashed"
        onClick={() => append({
          item: {
            _id: "",
            name: ""
          },
          quantity: 0,
          newPrice: 0,
          newFreight: 0,
          total: 0,
          description: "",
        })}
        block
        icon={<PlusOutlined />}
        size="large"
        style={{ 
          marginTop: '20px',
          height: '45px',
          borderColor: '#1890ff',
          color: '#1890ff',
          backgroundColor: '#f0f5ff',
          borderWidth: '2px',
          borderStyle: 'dashed',
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: '500'
        }}
      >
        Add Item
      </Button>
    </div>
  );
}
