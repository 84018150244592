import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialOrderContext from "../../../../../context/materialOrder/materialOrderContext";
import { Link } from "react-router-dom";
import moment from "moment";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Table, Button, Space} from "antd";
import ShopContext from "../../../../../context/shop/shopContext";

const MaterialPurchaseOrders = () => {
  const navigate = useNavigate();
  const shopContext = useContext(ShopContext);
  const { getShop } = shopContext;
  const materialOrderContext = useContext(MaterialOrderContext);
  const {
    getMaterialOrders,
    deleteMaterialOrder,
    updateMaterialOrder,
    materialOrders,
    setCurrentMaterialOrder,
    clearMaterialOrders,
    updateMaterialOrderRecieved,
    clearCurrentMaterialOrder,
  } = materialOrderContext;

  // Add a loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await getShop();
      await getMaterialOrders();
      clearCurrentMaterialOrder();
      setLoading(false);
    };
    
    loadData();

    return () => {
      // clearMaterialOrders();
    };
  }, []);

  useEffect(() => {}, [materialOrders]);

  const theTitle = "Material Orders";

  const handleRemoveMaterialOrder = (materialOrder) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Material Order?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteMaterialOrder(materialOrder);
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleViewMaterialOrder = (materialOrder) => {
    setCurrentMaterialOrder(materialOrder);
    navigate(`/shop/purchase/material/form`);
  };

  const onOrderChange = (e, record) => {
    let checked = e.target.checked;
    if (checked === true) {
      let TheRecievedDate = new Date();
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMaterialOrderRecieved(newRecord);
    } else {
      let TheRecievedDate = null;
      let newRecord = { ...record, recieved: checked, recievedDate: TheRecievedDate };
      updateMaterialOrderRecieved(newRecord);
    }
  };

  const columns = [
    {
      title: "PO#",
      dataIndex: "purchaseOrderNumber",
    },
    {
      title: "Vendor",
      render: (text, record) => <span>{record && record.vendor.name}</span>,
    },
    {
      title: "Ordered Date",
      dataIndex: "orderedDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.orderedDate).unix() - moment(b.orderedDate).unix(),
      render: (value, row, index) => {
        return <span>{value && new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved Date",
      dataIndex: "recievedDate",
      render: (value, row, index) => {
        return <span>{value && new Date(value).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Recieved",
      render: (text, record) => (
        <span>{<Checkbox checked={record && record.recieved} onChange={(e) => onOrderChange(e, record)} />}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "slug",
      key: "slug",
      width: 120,
      render: (text, record) => (
        <Space direction="vertical" size="small">
          <Button
            type="primary"
            danger
            onClick={() => handleRemoveMaterialOrder(record)}
            style={{
              width: 100
            }}
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={() => handleViewMaterialOrder(record)}
            style={{
              width: 100
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              {/* { loading ? (<h4 className='text-danger text-center'>Loading...</h4>) : (<h4 className='text-center'>{theTitle}</h4>)} */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/shop/purchase/material/form">
                <Button 
                  type="primary"
                  style={{ 
                    float: 'right',
                    marginBottom: '16px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '0 25px',
                    backgroundColor: '#52c41a', // A green color that suggests adding/creating
                    borderColor: '#52c41a'
                  }}
                >
                  Add New
                </Button>
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                rowClassName={() => "hover-row"}
                loading={loading}
                pagination={{ pageSize: 40 }}
                columns={columns}
                dataSource={materialOrders}
                rowKey="_id"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MaterialPurchaseOrders;
