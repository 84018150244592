import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  title: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  quantity: {
    width: "20%",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const InvoiceTableRow = ({ values }) => {
  if (!values?.items) return null;

  return (
    <>
      {values.items.map((item, index) => (
        <View wrap={false} key={index}>
          <View style={styles.container}>
            <View style={styles.row}>
              <Text style={styles.title}>{item?.item?.name || ''}</Text>
              <Text style={styles.title}>{item?.description || ''}</Text>
              <Text style={styles.quantity}>{item?.quantity || ''}</Text>
            </View>
          </View>
        </View>
      ))}
    </>
  );
};

export default InvoiceTableRow;
