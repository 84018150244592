import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Card, Row, Col, Typography } from "antd";

const { Title } = Typography;

const Items = ({ item, itemIndex, control, setValue, getValues, itemOptions, currentShop }) => {
  const setItemCount = (quantity) => {
    setValue(`standardItems[${itemIndex}].quantity`, quantity);
  };

  const setItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].item`, e);
    setValue(`standardItems[${itemIndex}].quantity`, 0);
  };

  // const productOptions =
  //   itemOptions &&
  //   itemOptions.map((item) => {
  //     const actualItem = item.item ? item.item : item;
  //     return { label: actualItem.name, value: actualItem };
  //   });
  const productOptions =
  itemOptions &&
  itemOptions.map((item) => {
    const actualItem = item.item ? item.item : item;
    const shopValue = actualItem.shopValues?.find(value => value.shop?.toString() === currentShop._id?.toString());
    // console.log("this is the shopValue", shopValue);
    const itemName = shopValue ? shopValue.name : actualItem.name;
    return { label: itemName, value: actualItem };
  });


  return (
    <Card
      style={{ marginBottom: "8px", boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)", border: "1px solid #e8e8e8", borderRadius: "8px" }}
    >
      <Row gutter={8}>
        <Col span={16}>
          {/* <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={productOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                isDisabled={true}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                onChange={(e) => setItemChange(e.value)}
              />
            )}
            key={item.id}
            name={`standardItems[${itemIndex}].item`}
            control={control}
          /> */}
           <Controller
            render={({ field }) => {
              // Get the shop-specific name for the selected value
              const selectedItem = field.value;
              const selectedShopValue = selectedItem?.shopValues?.find(
                value => value.shop?.toString() === currentShop._id?.toString()
              );
              const selectedName = selectedShopValue ? selectedShopValue.name : selectedItem?.name;

              return (
                <ReactSelect
                  {...field}
                  isDisabled={true}
                  options={productOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={field.value && { label: selectedName, value: field.value }}
                  onChange={(e) => setItemChange(e.value)}
                />
              );
            }}
            key={item.id}
            name={`standardItems[${itemIndex}].item`}
            control={control}
          />
        </Col>
        <Col span={8}>
          <Controller
            render={({ field, name }) => (
              <input
                className="form-control inputbg"
                readOnly
                type="number"
                step={0.01}
                name={name}
                {...field}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setItemCount(quantity);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].quantity`}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, setValue, getValues, allItems, currentShop }) {
  const { fields } = useFieldArray({
    control,
    name: "standardItems",
  });

  return (
    <div className="container-fluid">
      <Row className="mt-2">
        <Col span={16} className="text-center">
          <Title level={5}>Item Name</Title>
        </Col>
        <Col span={8} className="text-center">
          <Title level={5}>Count</Title>
        </Col>
      </Row>

      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
          currentShop={currentShop}
        />
      ))}
    </div>
  );
}
